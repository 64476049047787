/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosInstance';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoadingAnimation = () => {
  return (
    <div className="animate-pulse">
      <div className="w-[70%] h-[30px] bg-gray-300 m-2"></div>
      <div className="w-[30%] h-[30px] bg-gray-300 m-2"></div>
      <div className="w-[60%] h-[50px] bg-gray-300 m-2"></div>
      <div className="w-[80%] h-[20px] bg-gray-300 m-2"></div>
      <div className="w-[20%] h-[20px] bg-gray-300 m-2"></div>
      <div className="w-[90%] h-[30px] bg-gray-300 m-2"></div>
      <div className="w-[90%] h-[90px] bg-gray-300 m-2"></div>
      <div className="w-[80%] h-[20px] bg-gray-300 m-2"></div>
      <div className="w-[75%] h-[20px] bg-gray-300 m-2"></div>
      <div className="w-[20%] h-[20px] bg-gray-300 m-2"></div>
      <div className="w-[60%] h-[30px] bg-gray-300 m-2"></div>
      <div className="w-[90%] h-[40px] bg-gray-300 m-2"></div>
      <div className="w-[30%] h-[45px] bg-gray-300 m-2"></div>
    </div>
  );
};

const PseFeedback = (props) => {
  const { t } = useTranslation();
  const { userInfo } = props;
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [pseFBSource, setPseFBSource] = useState('');
  const [pseFormData, setPseFormData] = useState({});

  useEffect(() => {
    if (props.resultItem) {
      setPseFormData({
        serviceName: 'Solution Article',
        serviceID: '',
        spaceKey: props.resultItem.space
          ? props.resultItem.space
          : 'FB-0000011',
        articleID: props.resultItem.id ? props.resultItem.id : '',
        customField1: 'SolutionArticle',
        sourceCategory: 'Inform',
        feedbackURL: '',
        userCountryCode: '',
        valuemationID: 'FB-0000011',
        language: 'En'
      });
    }
  }, []);

  useEffect(() => {
    if (pseFBSource !== '') setLoader(false);
  }, [pseFBSource]);

  useEffect(() => {
    //build start Here
    if (pseFormData.articleID !== '' && open) buildPSEFeedbackForm();
  }, [open]);

  useEffect(() => {
    if (pseFormData.userCountryCode !== '') createFeedbackLink();
  }, [pseFormData]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUserCountryCode = () => {
    let getCountryUrl = `/api/v1/snow/user/country_code`;
    axiosInstance
      .post(getCountryUrl, { ntid: userInfo.userNTID })
      .then((response) => {
        if (response.status === 200 && response.data.isSuccessful) {
          setPseFormData({
            ...pseFormData,
            userCountryCode:
              response.data.data[0].result[0]['location.u_country_code']
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const buildPSEFeedbackForm = () => {
    let dataPayload = {
      searchvalue: 'MYMOBILE',
      searchfield: ''
    };
    let getValuemationUrl = `/api/v1/valuemation/space/details`;

    if (pseFormData.articleID !== '' && pseFormData.spaceKey !== '') {
      dataPayload.searchvalue = pseFormData.spaceKey;
      dataPayload.searchfield = 'spacekey';
      setPseFormData({ ...pseFormData, serviceName: 'Solution Article' });
    } else if (pseFormData.serviceName) {
      dataPayload.searchvalue = pseFormData.serviceName;
      dataPayload.searchfield = 'spacename';
    }

    axiosInstance
      .post(getValuemationUrl, dataPayload)
      .then((response) => {
        // If valuemation was retrieved successfully, retrieve country code.
        if (response.status === 200 && response.data.VALUEMATION_ID) {
          setPseFormData({
            ...pseFormData,
            valuemationID: response.data.VALUEMATION_ID
          });
        }
        getUserCountryCode();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const createFeedbackLink = () => {
    // Click Identifier Data
    let feedbackLink = '';
    var sessionId = ''; //sessionStorage.getItem('prevSessionId');
    var searchId = ''; //sessionStorage.getItem('lastAnalyticSearchId') ? sessionStorage.getItem('lastAnalyticSearchId') : '';

    var year = new Date().getFullYear().toString();
    var month = new Date().getMonth().toString();
    var dayNotProcesed = new Date().toString();
    var day = dayNotProcesed.slice(8, 10);
    var hour = new Date().getHours().toString();
    var minutes = new Date().getMinutes().toString();
    var ms = new Date().getMilliseconds().toString();
    var separator = ':';
    var randomString = Math.random().toString(36).substring(2, 16);
    var clickIdentifier =
      year +
      month +
      day +
      separator +
      hour +
      separator +
      minutes +
      separator +
      ms +
      separator +
      randomString;

    var customField2 = {
      clickIdentifier: clickIdentifier,
      sessionId: sessionId,
      searchId: searchId,
      article_version_id: pseFormData.articleID
        ? pseFormData.articleID
        : pseFormData.serviceID
        ? pseFormData.serviceID
        : ''
    };

    var customField2Encoded = encodeURIComponent(JSON.stringify(customField2));

    var feedbackStruct = {
      offeringID: pseFormData.valuemationID ? pseFormData.valuemationID : '', //VALUEMATION ID
      offeringName: pseFormData.serviceName ? pseFormData.serviceName : '', //P/S/S ?  P/S/S : NAME OF THE ORDER FORM
      pageID: pseFormData.articleID
        ? pseFormData.articleID
        : pseFormData.serviceID
        ? pseFormData.serviceID
        : '', // SOLUTION ARTICLE ID
      source: 'ITSP',
      sourceCategory: pseFormData.sourceCategory
        ? pseFormData.sourceCategory
        : 'Inform',
      stage: 'Dev', //Prod OR Dev
      surveyStyle: 'Flyout',
      customField2: customField2Encoded, // URL escaped JSON String of the event data
      version: '', //undefined - NOT MANDATORY
      language: pseFormData.language ? pseFormData.language : 'En', //To support different languages in the future
      userCountryID: pseFormData.countryCode, //Bosch Country ID of the user
      userGB: '' // Bosch GB id
    };

    // Validate origin to define environment
    if (process.env.NODE_ENV === 'production') {
      feedbackStruct.stage = 'Prod';
    } else {
      feedbackStruct.stage = 'Dev';
    }
    // Validate if there's an existing valuemationID to generate feedback link
    if (feedbackStruct.offeringID !== '') {
      //
      feedbackLink =
        'https://survey.bosch.com/cgi-bin/s.app?A=z61i3Lhm&OfferingID=' +
        feedbackStruct.offeringID +
        (feedbackStruct.offeringName
          ? '&Offering=' + encodeURIComponent(feedbackStruct.offeringName)
          : '&Offering=ITSP%20(Unmapped)') +
        (feedbackStruct.source
          ? '&Source=' + encodeURIComponent(feedbackStruct.source)
          : '') +
        (feedbackStruct.sourceCategory
          ? '&SourceCategory=' +
            encodeURIComponent(feedbackStruct.sourceCategory)
          : '') +
        (feedbackStruct.stage
          ? '&Stage=' + encodeURIComponent(feedbackStruct.stage)
          : '') +
        (feedbackStruct.surveyStyle
          ? '&SurveyStyle=' + encodeURIComponent(feedbackStruct.surveyStyle)
          : '') +
        (pseFormData.customField1
          ? '&CustomField1=DescriptionPage'
          : '&CustomField1=Solution%20Article') +
        (feedbackStruct.customField2
          ? '&CustomField2=' + encodeURIComponent(feedbackStruct.customField2)
          : '') +
        (feedbackStruct.version
          ? '&Version=' + encodeURIComponent(feedbackStruct.version)
          : '') +
        (feedbackStruct.language
          ? '&Language=' + encodeURIComponent(feedbackStruct.language)
          : '') +
        (feedbackStruct.userCountryID
          ? '&UserCountryID=' + encodeURIComponent(feedbackStruct.userCountryID)
          : '') +
        (feedbackStruct.userGB
          ? '&UserGB=' + encodeURIComponent(feedbackStruct.userGB)
          : '') +
        (feedbackStruct.pageID
          ? '&PageID=' + encodeURIComponent(feedbackStruct.pageID)
          : '');
    }
    setPseFBSource(feedbackLink);
  };

  return (
    <Grid
      container
      className="font-Bosch_sans"
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <div className="text-lg text-neutral-800 ml-3.5 mr-2.5">
        {' '}
        {t('ui_spear.support.search_want_tell_us_more')}{' '}
      </div>
      <Button
        className="rounded-none bg-feedback_btn hover:bg-feedback_btn_hover mx-4 px-2"
        onClick={handleClickOpen}
      >
        <div className="text-[14px] text-white normal-case px-3">
          <div className="boschicon-bosch-ic-thumb-up-down mx-1 inline-block text-lg"></div>
          {t('ui_spear.search.Modal.Share_feedback')}
        </div>
      </Button>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="flex justify-end items-center h-3">
          <IconButton
            className="boschicon-bosch-ic-close text-3xl mt-3"
            aria-label="close"
            onClick={handleClose}
          ></IconButton>
        </DialogTitle>
        <DialogContent>
          {loader ? (
            <LoadingAnimation />
          ) : (
            <iframe
              src={pseFBSource}
              frameBorder="0"
              id="pse-feedback-iframe"
              width={'100%'}
              height={680}
              title={'unique'}
            ></iframe>
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default PseFeedback;
